import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    MODELGAMING: "Game",
    SOLUTION: "Software Development",
  };

  const projectsData = [
    {
      title: "Pet Raising",
      projectInfo: "Interaction with a pet, feeding and decorate the house.",
      // client: "Coming soon",
      technologies: "Beldner 3.6 | Unity | Node.js | AWS Cloud | Mongodb",
      // industry: "Coming soon",
      date: "8/8/2022",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },
      title: "Pet Raising",
      projectInfo:
        "To make a memorial place for the pet. Interaction with a pet, feeding and decorate the house.",
      client: "/",
      technologies: "Beldner 3.6 | Unity | Node.js | AWS Cloud | Mongodb",
      industry: "/",
      date: "8/8/2022",
      url: {
        name: "/",
        link: "/",
      },
      // socialLinks: {
      //   facebook: "http://www.facebook.com/",
      //   twitter: "http://www.twitter.com/",
      //   google: "http://www.google.com/",
      //   instagram: "http://www.instagram.com/",
      //   mail: "Coming soon",
      // },
      thumbImage: "images/projects/VID-20230807-WA0002.gif",
      // sliderImages: [
      //   "images/projects/VID-20230807-WA0002.gif",
      //   "images/projects/VID-20230807-WA0002.gif",
      // ],
      categories: [filters.MODELGAMING],
    },
    {
      title: "Chat Room and messaging mobile app",
      projectInfo:
        "For agency to chat with client, for client to search for agency",
      // client: "Ruby Clinton",
      technologies: "Web Socket, React",
      // industry: "Art & Design",
      date: "May 16, 2024",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },

      thumbImage: "images/projects/chatroom_thumbnail.gif",
      sliderImages: ["images/projects/chatroom_cut_error.gif"],
      categories: [filters.SOLUTION],
    },
    {
      title: "Customer managing system",
      projectInfo:
        "Inventory for agencies and customers, supports copy and paste from Excel",
      // client: "Ruby Clinton",
      technologies: "AWS, React",
      // industry: "Art & Design",
      date: "May 16, 2024",
      // url: {
      //   name: "www.example.com",
      //   link: "https://www.example.com",
      // },

      thumbImage: "images/projects/admin_cut.gif",
      sliderImages: ["images/projects/admin_cut.gif"],
      categories: [filters.SOLUTION],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              Our Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        {/* <video width="400" controls>
                            <source src={project.thumbImage}/>
                        </video> */}
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
